<template>
  <div>
    <!-- 增加两个勾选项，“我想成为案主”和“我想成为主持人”； -->
    <div class="radio-box fs28">
      <!-- <van-radio-group
        v-model="radio"
        class="dfc main-around h100"
        @change="changeRad"
      >
        <van-radio
          name="1"
          shape="square"
          checked-color="#F2C984"
          @click="radClick()"
          >我想当案主</van-radio
        >
        <van-radio
          name="2"
          shape="square"
          checked-color="#F2C984"
          @click="radClick()"
          >我想成为主持人</van-radio
        >
      </van-radio-group> -->
      <van-checkbox-group v-model="result" class="dfc main-around h100">
        <van-checkbox name="1" checked-color="#F2C984" shape="square"
          >我想当案主</van-checkbox
        >
        <van-checkbox name="2" checked-color="#F2C984" shape="square"
          >我想成为主持人</van-checkbox
        >
      </van-checkbox-group>
    </div>
    <div class="title fs28 fc666">参会感想</div>
    <div class="inner help">
      <van-field
        class="h100"
        v-model.trim="message"
        rows="20"
        type="textarea"
        placeholder="说说你的参会感想"
      />
    </div>

    <div class="b-button-box bgfff">
      <div class="recruiting-button dfc flex-center">
        <div @click="helpSubmit" class="dfr flex-center">
          <icy-button height="0.8rem" border-radius="0.44rem"
            >添加我的参会感想</icy-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      id: "",
      routerType: 0,
      // 是否私董
      isSidong: "",
      result: [],
      // 勾选了传递1 ,不勾选不传
      want_become_driven: "", //想成为案主
      want_become_host: "" //想成为主持人
    };
  },
  created() {
    this.$title("说说参会感想");
    this.id = parseInt(this.$route.query.id) || "";
    this.routerType = parseInt(this.$route.query.type) || 0;
    this.isSidong = parseInt(this.$route.query.isSidong);
  },
  mounted() {},
  methods: {
    async helpSubmit() {
      if (!this.message) {
        this.$toast("请填写你的参会感想内容");
        return;
      }
      if (!this.id) {
        this.$toast("未找到对应会议");
        this.$goReplace("home");
        return;
      }
      let data = {
        id: this.id,
        content: this.message
      };
      //  radio:1 想当案主 radio:2 想成为主持人
      if (this.result.length) {
        if (this.result.length == 1) {
          if (this.result[0] == 1) {
            data = {
              id: this.id,
              content: this.message,
              want_become_driven: 1
            };
          } else {
            data = {
              id: this.id,
              content: this.message,
              want_become_host: 1
            };
          }
        } else if (this.result.length == 2) {
          data = {
            id: this.id,
            content: this.message,
            want_become_driven: 1,
            want_become_host: 1
          };
        }
      }

      const resp = await this.$API
        .post("addFeelingContent", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("添加成功");
        this.$goReplace("meetingDetail", {
          active: 4,
          id: this.id,
          type: this.routerType
        });
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.title {
  padding: 0.36rem;
  height: 1rem;
}
.inner {
  width: 6.78rem;
  margin: 0 auto;
  min-height: calc(100vh - 1rem - 1.8rem - 2.1rem);
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .inner {
    min-height: calc(100vh - 1rem - 1.8rem - 2.1rem); //- 15px - 1.8rem
  }
}

// iphone Xs Max
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .inner {
    min-height: calc(100vh - 1rem - 1.8rem - 2.1rem); //- 15px - 1.8rem
  }
}

// iphone XR
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .inner {
    min-height: calc(100vh - 1rem - 1.8rem - 2.1rem); //- 15px - 1.8rem
  }
}
.radio-box {
  width: 7.5rem;
  height: 1.8rem;
  padding: 0.2rem 0.36rem;
  box-sizing: border-box;
  border-bottom: 0.01rem solid #eee;
}

.b-button-box {
  height: calc(1.2rem + env(safe-area-inset-bottom));
  height: calc(1.2rem + constant(safe-area-inset-bottom));
  .recruiting-button {
    padding: 0.2rem 0.36rem;
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
