import { render, staticRenderFns } from "./createFeeling.vue?vue&type=template&id=f1e74014&scoped=true&"
import script from "./createFeeling.vue?vue&type=script&lang=js&"
export * from "./createFeeling.vue?vue&type=script&lang=js&"
import style0 from "./createFeeling.vue?vue&type=style&index=0&id=f1e74014&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1e74014",
  null
  
)

export default component.exports